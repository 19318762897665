import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { RootState } from "../../../app/store"
import { login } from "../api/loginAPI"

export interface LoginSlice {
  user: any,
  status: "idle" | "loading" | "loaded" | "failed"
}

const initialState: LoginSlice = {
  user: null,
  status: "idle",
}

export const loginAsync = createAsyncThunk(
  "auth/login",
  async (data: any) => {
    
    data["grant_type"] = null
    data["client_id"] = null
    data["client_secret"] = null

    const response = await login(data)

    // The value we return becomes the `fulfilled` action payload
    return response.data
  },
)

export const loginSlice = createSlice({
  name: "login",
  initialState,
  reducers: {
    logout: () => initialState
  },
  extraReducers: (builder) => {
    builder
      .addCase(loginAsync.pending, (state) => {
        state.status = "loading"
      })
      .addCase(loginAsync.fulfilled, (state, action) => {
        state.status = "loaded"
        state.user = action.payload
      })
      .addCase(loginAsync.rejected, (state) => {
        state.status = "failed"
      })
    
      // .addCase(checkSessionAsync.fulfilled, (state, action) => {
      //   // state.user = action.payload
      // })
  }
})

export const { logout } = loginSlice.actions


export const userLogin = (state: RootState) => state.login.user
export const statusLogin = (state: RootState) => state.login.status

export default loginSlice.reducer