import { authAxios } from "../../../app/shared/api";

export async function createMarket(name: string, acronym: string, link_yahoo: string) {
  return await authAxios.post(`market/create`, {name, acronym, link_yahoo})
}

export async function fetchMarkets() {
  return await authAxios.get(`market/all`)
}

export async function fetchMarketsMin() {
  return await authAxios.get(`market/min`)
}

export async function updateMarket(marketId: string, data: any) {
  return await authAxios.put(`market/${marketId}`, data)
}

export async function deleteMarket(marketId: string) {
  return await authAxios.delete(`market/${marketId}`)
}