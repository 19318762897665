import { Navigate } from "react-router-dom"

import { useAppDispatch, useAppSelector } from "../hooks"
import { logout, userLogin } from "../../features/auth/slice/loginSlice"
import { authAxios } from "./api"

const ProtectedRoute = ({ children }: {
  children: React.ReactNode,
}) => {
  const user = useAppSelector(userLogin)
  const dispatch = useAppDispatch()

  if (!user) {
    return <Navigate to="/" replace />
  }

  authAxios.defaults.headers.common['Authorization'] = `Bearer ${user.access_token}`

  authAxios.interceptors.response.use(
    function (response) {
      return response;
    },
    function (error) {
      if (error.response.status === 401) {
        dispatch(logout())
      }
      return Promise.reject(error);
    }
  );

  return children
}

export default ProtectedRoute