import "./App.css"
import {
  RouterProvider,
} from "react-router-dom"
import router from "./app/router"
import { ToastContainer } from "react-toastify"
import 'react-toastify/dist/ReactToastify.min.css';

function App() {
  return (
    <>
      <RouterProvider router={router} />
      <ToastContainer />
    </>
    
  )
}

export default App
