import { authAxios } from "../../../app/shared/api";

export async function fetchCompanies(limit: number, skip: number, search: string = '') {
  return await authAxios.get(`company/list?limit=${limit}&skip=${skip}&search=${search}`)
}

export async function createCompany(data: any) {
  return await authAxios.post(`company/create`, data)
}

export async function fetchCompanyGraphs(companyId: string) {
  return await authAxios.get(`company/${companyId}/graphs`)
}

export async function setCompanyGraph(companyId: string, chosen: number) {
  return await authAxios.put(`company/${companyId}/graph`, { chosen_graph: chosen })
}

export async function updateCompany(companyId: string, data: any) {
  return await authAxios.put(`company/${companyId}`, data)
}

export async function deleteCompany(companyId: string) {
  return await authAxios.delete(`company/${companyId}`)
}

export async function updateFileCompany(companyId: string) {
  return await authAxios.put(`company/${companyId}/update_file`)
}