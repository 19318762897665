export function fetchStock(stock: string) {
  return new Promise<{ data: string[] }>(
    (resolve) =>
    setTimeout(
      () => resolve({ data: ["Apple", "M3", "Disney", "StationX", "Microsoft", "Netflix", "HBO"] }), 
      2000,
    ),
  )
}

export function fetchValuationStock(stockId: string) {
  return new Promise<{ data: string }>(
    (resolve) =>
    setTimeout(
      () => resolve({ data: "Valuation" }), 
      2000,
    ),
  )
}