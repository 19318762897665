import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { RootState } from "../../../app/store"
import { fetchCompanies, fetchCompanyGraphs, setCompanyGraph, createCompany, updateCompany, deleteCompany, updateFileCompany } from "../api/companiesAPI"
import { getProcessStatus } from "../api/processStatusAPI"

export interface CompaniesSlice {
  companies: any
  graphs: any,
  status: "idle" | "loading" | "loaded" | "failed",
  statusChosenGraph: "idle" | "loading" | "loaded" | "failed",
  statusGraphs: "idle" | "loading" | "loaded" | "failed",
  statusCreate: "idle" | "loading" | "loaded" | "failed",
  statusDelete: "idle" | "loading" | "loaded" | "failed",
  onErrorCreate: any,
  onErrorGraphs: any,
  processStatus: any,
}

const initialState: CompaniesSlice = {
  companies: {count: 0, items: []},
  graphs: null,
  status: "idle",
  statusChosenGraph: "idle",
  statusGraphs: "idle",
  statusCreate: "idle",
  statusDelete: "idle",
  onErrorCreate: null,
  onErrorGraphs: null,
  processStatus: null,
}

export const fetchCompaniesAsync = createAsyncThunk(
  "companies/fetchAll",
  async (data: any) => {
    const response = await fetchCompanies(data.limit, data.skip * data.limit, data.search)

    return response.data
  },
)

export const fetchCompaniesSilentAsync = createAsyncThunk(
  "companies/fetchSilent",
  async (data: any) => {
    const response = await fetchCompanies(data.limit, data.skip * data.limit, data.search)

    return response.data
  },
)

export const createCompanyAsync = createAsyncThunk(
  "companies/create",
  async (data: any, { rejectWithValue }) => {
    try {
      const response = await createCompany({ acronym: data.acronym, markets: [data.markets.id] })
    
      return response.data
    }
    catch (error: any) {
      return rejectWithValue(error.response.data)
    }
  }
)

export const updateCompanyAsync = createAsyncThunk(
  "companies/update",
  async (data: any, { rejectWithValue }) => {
    try {
      const response = await updateCompany(data.companyId, { markets: [data.markets.id] })
      
      return response.data
    }
    catch (error: any) {
      return rejectWithValue(error.response.data)
    }
  }
)

export const deleteCompanyAsync = createAsyncThunk(
  "companies/delete",
  async (companyId: string) => {
    const response = await deleteCompany(companyId)

    return response.data
  }
)

export const fetchCompanyGraphsAsync = createAsyncThunk(
  "company/graph",
  async (companyId: string, { rejectWithValue }) => {
    try {
      const response = await fetchCompanyGraphs(companyId)

      return response.data
    }
    catch (error: any) {
      return rejectWithValue(error.response.data)
    }
  }
)

export const setCompanyGraphAsync = createAsyncThunk(
  "company/setgraph",
  async (data: any) => {
    const response = await setCompanyGraph(data.companyId, data.chosen)

    return response.data
  }
)

export const updateFileCompanyAsync = createAsyncThunk(
  "company/updatefile",
  async (companyId: string) => {
    const response = await updateFileCompany(companyId)

    return response.data
  }
)

export const fetchProcessStatusAsync = createAsyncThunk(
  "processStatus/fetch",
  async (process: string) => {
    const response = await getProcessStatus(process)

    return response.data
  }
)

export const companiesSlice = createSlice({
    name: "companies",
    initialState,
    reducers: {
      resetError: (state) => {
        state.onErrorCreate = null
      }
    },
    extraReducers: (builder) => {
      builder
            // Normal
            .addCase(fetchCompaniesAsync.pending, (state) => {
              state.status = "loading"
            })
            .addCase(fetchCompaniesAsync.fulfilled, (state, action) => {
              state.companies = action.payload
              state.status = "loaded"
            })
            .addCase(fetchCompaniesAsync.rejected, (state) => {
              state.status = "failed"
            })
            
            // Silent
            .addCase(fetchCompaniesSilentAsync.fulfilled, (state, action) => {
              state.companies = action.payload
            })
            
            // Graphs
            .addCase(fetchCompanyGraphsAsync.pending, (state) => {
              state.statusGraphs = "loading"
              state.onErrorGraphs = null
            })
            .addCase(fetchCompanyGraphsAsync.fulfilled, (state, action) => {
              state.graphs = action.payload
              state.statusGraphs = "loaded"
              state.onErrorGraphs = null
            })
            .addCase(fetchCompanyGraphsAsync.rejected, (state, action) => {
              state.statusGraphs = "failed"
              state.onErrorGraphs = action.payload
            })
      
            // Chosen Graph
            .addCase(setCompanyGraphAsync.pending, (state) => {
              state.statusChosenGraph = "loading"
            })
            .addCase(setCompanyGraphAsync.fulfilled, (state) => {
              state.statusChosenGraph = "loaded"
            })
            .addCase(setCompanyGraphAsync.rejected, (state) => {
              state.statusChosenGraph = "failed"
            })
            
            // Create Company
            .addCase(createCompanyAsync.pending, (state) => {
              state.statusCreate = "loading"
              state.onErrorCreate = null
            })
            .addCase(createCompanyAsync.fulfilled, (state) => {
              state.statusCreate = "loaded"
              state.onErrorCreate = null
            })
            .addCase(createCompanyAsync.rejected, (state, action) => {
              state.statusCreate = "failed"
              state.onErrorCreate = action.payload
            })
      
            // Update Company
            .addCase(updateCompanyAsync.pending, (state) => {
              state.statusCreate = "loading"
              state.onErrorCreate = null
            })
            .addCase(updateCompanyAsync.fulfilled, (state) => {
              state.statusCreate = "loaded"
              state.onErrorCreate = null
            })
            .addCase(updateCompanyAsync.rejected, (state, action) => {
              state.statusCreate = "failed"
              state.onErrorCreate = action.payload
            })
      
            // Delete Company
            .addCase(deleteCompanyAsync.pending, (state) => {
              state.statusDelete = "loading"
            })
            .addCase(deleteCompanyAsync.fulfilled, (state) => {
              state.statusDelete = "loaded"
            })
            .addCase(deleteCompanyAsync.rejected, (state) => {
              state.statusDelete = "failed"
            })
      
            .addCase(fetchProcessStatusAsync.fulfilled, (state, action) => {
              state.processStatus = action.payload
            })
    }
})

export const { resetError } = companiesSlice.actions

export const statusCompanies = (state: RootState) => state.companies.status
export const valueCompanies = (state: RootState) => state.companies.companies
export const statusGraphs = (state: RootState) => state.companies.statusGraphs
export const valueGraphs = (state: RootState) => state.companies.graphs
export const statusChosenGraph = (state: RootState) => state.companies.statusChosenGraph
export const statusCreateCompany = (state: RootState) => state.companies.statusCreate
export const statusDeleteCompany = (state: RootState) => state.companies.statusDelete
export const onErrorCreateCompany = (state: RootState) => state.companies.onErrorCreate
export const onErrorGraphsCompany = (state: RootState) => state.companies.onErrorGraphs
export const valueProcessStatus = (state: RootState) => state.companies.processStatus

export default companiesSlice.reducer