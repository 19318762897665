import { configureStore, ThunkAction, Action } from "@reduxjs/toolkit"
import counterReducer from "../features/counter/counterSlice"
import stockReducer from "../features/stock/stockSlice"
import companiesReducer from "../features/admin/slice/companiesSlice"
import loginReducer from "../features/auth/slice/loginSlice"
import marketReducer from "../features/admin/slice/marketsSlice"

import { persistReducer, persistStore, FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist"
import storage from 'redux-persist/lib/storage';

const persistConfig = {
  key: "root",
  version: 1,
  storage: storage,
}

const persistedReducer = persistReducer(persistConfig, loginReducer)

export const store = configureStore({
  reducer: {
    login: persistedReducer,
    counter: counterReducer,
    stock: stockReducer,
    companies: companiesReducer,
    markets: marketReducer,
  },
  devTools: process.env.NODE_ENV !== "production",
  middleware: (getDefaultMiddleware) =>
  getDefaultMiddleware({
    serializableCheck: {
      ignoredActions: [
        REHYDRATE,
        PAUSE,
        PERSIST,
        PURGE,
        REGISTER,
      ],
    },
  })
})

export const persistor = persistStore(store)

export type AppDispatch = typeof store.dispatch
export type RootState = ReturnType<typeof store.getState>
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>
