import axios from "axios";
import { API_URL } from "../../../app/shared/api";

export async function login(data: any) {
  return await axios.post(`${API_URL}auth/login`, data, {
    headers: { 
      "Content-Type": "application/x-www-form-urlencoded"
    }
  })
}

export async function checkSession(token: string) {
  return await axios.get(`${API_URL}user/me`, {
    headers: {
      "Authorization": `Bearer ${token}`
    }
  })
}