import { lazy, Suspense } from 'react';
import {
    createBrowserRouter,
} from "react-router-dom";

import ProtectedRoute from "./shared/ProtectedRoute";

const Login = lazy(() => import("../features/auth/Login"));
const AdminLayout = lazy(() => import("../features/admin/AdminLayout"));
const AdminCompanies = lazy(() => import("../features/admin/Companies"));
const AdminMarkets = lazy(() => import("../features/admin/Markets"));
const AdminCompanyGraphs = lazy(() => import("../features/admin/CompanyGraphs"));

const router = createBrowserRouter([
  {
    path: "/",
    element: <Suspense><Login /></Suspense>
  },
  {
    path: "/dashboard",
    element: <ProtectedRoute><Suspense><AdminLayout /></Suspense></ProtectedRoute>,
    children: [
      {
        path: "companies",
        element: <Suspense><AdminCompanies /></Suspense>,
      },
      {
        path: "markets",
        element: <Suspense><AdminMarkets /></Suspense>
      },
      {
        path: "companies/:companyId",
        element: <Suspense><AdminCompanyGraphs /></Suspense>
      }
    ]
  }
])

export default router