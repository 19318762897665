import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { fetchMarkets, createMarket, fetchMarketsMin, updateMarket, deleteMarket } from "../api/marketsAPI"
import { RootState } from "../../../app/store"

export interface MarketsSlice {
  markets: any[]
  status: "idle" | "loading" | "loaded" | "failed",
  statusCreate: "idle" | "loading" | "loaded" | "failed",
  onErrorCreate: any,
  marketsMin: any[],
  statusMin: "idle" | "loading" | "loaded" | "failed",
  statusDelete: "idle" | "loading" | "loaded" | "failed",
}

const initialState: MarketsSlice = {
  markets: [],
  status: "idle",
  statusCreate: "idle",
  onErrorCreate: null,
  marketsMin: [],
  statusMin: "idle",
  statusDelete: "idle",
}

export const createMarketAsync = createAsyncThunk(
  "markets/create",
  async (data: any, { rejectWithValue }) => {
    try {
      const response = await createMarket(data.name, data.acronym, data.link_yahoo)
      return response.data
    } catch (error: any) {
      return rejectWithValue(error.response.data)
    }
  }
)

export const updateMarketAsync = createAsyncThunk(
  "markets/update",
  async (data: any, { rejectWithValue }) => {
    try {
      const response = await updateMarket(data.marketId, data.market)
      return response.data
    } catch (error: any) {
      return rejectWithValue(error.response.data)
    }
  }
)

export const deleteMarketAsync = createAsyncThunk(
  "markets/delete",
  async (marketId: string) => {
    const response = await deleteMarket(marketId)

    return response.data
  }
)

export const fetchMarketsAsync = createAsyncThunk(
  "markets/fetchAll",
  async () => {
    const response = await fetchMarkets()

    return response.data
  }
)

export const fetchMarketsMinAsync = createAsyncThunk(
  "markets/fetchMin",
  async () => {
    const response = await fetchMarketsMin()

    return response.data
  }
)

export const marketsSlice = createSlice({
  name: "markets",
  initialState,
  reducers: {
    resetError: (state) => {
      state.onErrorCreate = null
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchMarketsAsync.pending, (state) => {
        state.status = "loading"
      })
      .addCase(fetchMarketsAsync.fulfilled, (state, action) => {
        state.markets = action.payload
        state.status = "loaded"
      })
      .addCase(fetchMarketsAsync.rejected, (state) => {
        state.status = "failed"
      })
      
      // Create
      .addCase(createMarketAsync.pending, (state) => {
        state.statusCreate = "loading"
        state.onErrorCreate = null
      })
      .addCase(createMarketAsync.fulfilled, (state, action) => {
        state.statusCreate = "loaded"
        state.onErrorCreate = null
      })
      .addCase(createMarketAsync.rejected, (state, action) => {
        state.statusCreate = "failed"
        state.onErrorCreate = action.payload
      })

      // Update
      .addCase(updateMarketAsync.pending, (state) => {
        state.statusCreate = "loading"
        state.onErrorCreate = null
      })
      .addCase(updateMarketAsync.fulfilled, (state, action) => {
        state.statusCreate = "loaded"
        state.onErrorCreate = null
      })
      .addCase(updateMarketAsync.rejected, (state, action) => {
        state.statusCreate = "failed"
        state.onErrorCreate = action.payload
      })
      
      // Fetch Min
      .addCase(fetchMarketsMinAsync.pending, (state) => {
        state.statusMin = "loading"
      })
      .addCase(fetchMarketsMinAsync.fulfilled, (state, action) => {
        state.statusMin = "loaded"
        state.marketsMin = action.payload
      })
      .addCase(fetchMarketsMinAsync.rejected, (state, action) => {
        state.statusMin = "failed"
      })
    
      // Delete
      .addCase(deleteMarketAsync.pending, (state) => {
        state.statusDelete = "loading"
      })
      .addCase(deleteMarketAsync.fulfilled, (state, action) => {
        state.statusDelete = "loaded"
      })
      .addCase(deleteMarketAsync.rejected, (state, action) => {
        state.statusDelete = "failed"
      })
  }
})

export const { resetError} = marketsSlice.actions

export const statusMarkets = (state: RootState) => state.markets.status
export const valuesMarkets = (state: RootState) => state.markets.markets
export const statusCreateMarket = (state: RootState) => state.markets.statusCreate
export const statusDeleteMarket = (state: RootState) => state.markets.statusDelete
export const onErrorCreateMarket = (state: RootState) => state.markets.onErrorCreate
export const valuesMarketsMin = (state: RootState) => state.markets.marketsMin

export default marketsSlice.reducer