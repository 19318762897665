import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit"
import { RootState, AppThunk } from "../../app/store"
import { fetchStock, fetchValuationStock } from "./stockAPI"

export interface StockState {
  value: string[]
  status: "idle" | "loading" | "loaded" | "failed"
  statusValuation: "idle" | "loading" | "loaded" | "failed"
  selectedStock: string,
  valuationStock: string
}

const initialState: StockState = {
  value: [],
  status: "idle",
  statusValuation: "idle",
  selectedStock: "",
  valuationStock: "",
}

// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched. Thunks are
// typically used to make async requests.
export const fetchAsync = createAsyncThunk(
  "stock/fetchStock",
  async (stock: string) => {
    const response = await fetchStock(stock)
    // The value we return becomes the `fulfilled` action payload
    return response.data
  },
)

export const fetchValuationAsync = createAsyncThunk(
  "stock/fetchValuationStock",
  async (stockId: string) => {
    const response = await fetchValuationStock(stockId)
    // The value we return becomes the `fulfilled` action payload
    return response.data
  },
)

export const stockSlice = createSlice({
  name: "stock",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    setStock: (state, action: PayloadAction<string>) => {
      state.selectedStock = action.payload
    },

    resetStock: () => initialState
    // increment: (state) => {
    //   // Redux Toolkit allows us to write "mutating" logic in reducers. It
    //   // doesn't actually mutate the state because it uses the Immer library,
    //   // which detects changes to a "draft state" and produces a brand new
    //   // immutable state based off those changes
    //   state.value += 1
    // },
    // decrement: (state) => {
    //   state.value -= 1
    // },
    // // Use the PayloadAction type to declare the contents of `action.payload`
    // incrementBystock: (state, action: PayloadAction<number>) => {
    //   state.value += action.payload
    // },
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(fetchAsync.pending, (state) => {
        state.status = "loading"
      })
      .addCase(fetchAsync.fulfilled, (state, action) => {
        state.status = "loaded"
        state.value = action.payload
      })
      .addCase(fetchAsync.rejected, (state) => {
        state.status = "failed"
      })

      .addCase(fetchValuationAsync.pending, (state) => {
        state.statusValuation = "loading"
      })
      .addCase(fetchValuationAsync.fulfilled, (state, action) => {
        state.statusValuation = "loaded"
        state.valuationStock = action.payload
      })
      .addCase(fetchValuationAsync.rejected, (state) => {
        state.statusValuation = "failed"
      })
  },
})

export const { setStock, resetStock } = stockSlice.actions

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectStock = (state: RootState) => state.stock.value
export const statusStock = (state: RootState) => state.stock.status
export const selectedStock = (state: RootState) => state.stock.selectedStock
export const statusValuationStock = (state: RootState) => state.stock.statusValuation
export const valuationStock = (state: RootState) => state.stock.valuationStock

// We can also write thunks by hand, which may contain both sync and async logic.
// Here's an example of conditionally dispatching actions based on current state.
// export const incrementIfOdd =
//   (stock: number): AppThunk =>
//   (dispatch, getState) => {
//     const currentValue = selectCount(getState())
//     if (currentValue % 2 === 1) {
//       dispatch(incrementBystock(stock))
//     }
//   }

export default stockSlice.reducer
